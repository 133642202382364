html,
body,
#root,
.App,
.Desktop,
.loading {
  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont;
  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.about {
  position: fixed;
  top: 10%;
  width: 570px;
  background: white;
  border-radius: 5px;
}

.about-inner {
  margin: 40px 40px 35px 40px;
  text-align: left;
}

.about-icon {
  float: left;
}

.about-info {
  margin-left: 40%;
}

.tetris-container {
  background-color: teal;
  border-radius: 5px;
  /* position: fixed;
  top: 45%;
  left: 50%;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); */
}

.software {
  position: fixed;
  top: 48%;
  left: 70%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.App {
  text-align: center;
}

.browser-frame {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Desktop {
  display: flex;
  justify-content: center;
  align-self: flex-end;
  /* background: url(images/desktop.jpg); */
  background: linear-gradient(
    rgb(255, 255, 65),
    rgb(255, 166, 0),
    rgb(0, 166, 255),
    #ffb3ff
  );
  animation: change 20s ease-in-out infinite;
  background-size: 400% 400%;
  background-position: center;
}

@keyframes change {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background: url(images/desktop-blur.jpg); */
  background: linear-gradient(rgb(254, 199, 96), rgb(157, 221, 255));
  background-size: cover;
  background-position: center;
}

.profile-circular {
  width: 100px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.profile-circular-about {
  width: 150px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  border: 5px solid lightgrey;
}

.username {
  letter-spacing: 0.02em;
  margin-top: 1.8em;
  margin-bottom: 1.8em;
  color: white;
}

.dock {
  display: grid;
  grid-template-columns: auto auto auto;
  align-self: flex-end;
}

.dock-bg {
  background-color: #ccc;
  opacity: 0.6;
  border-radius: 4px 4px 0 0;
  box-shadow: 1px 1px 50px 4px rgba(0, 0, 0, 0.8);
}
.dock-item {
  padding: 7px;
}

.dock-img {
  max-width: 100%;
  max-height: 100%;
}

.terminal-container {
  border-radius: 5% 5% 0 0;
}

.terminal-base {
  height: 100%;
  min-height: 0px;
}

.tetris-inner-container {
  display: flex;
  gap: 20px;
  margin: 30px;
}

.game-stats {
  text-align: left;
  font-weight: 900;
  font-size: 16px;
  color: white;
}

.game-block {
  margin: 0;
  padding: 0;
  width: 1.3em;
  height: 1.3em;
  border: 1px solid #ddd;
}

#tetris-header {
  font-weight: 900;
  color: white;
  margin-bottom: 0;
  margin-top: 20px;
  letter-spacing: 0.3em;
}

.points {
  margin-top: 1em;
  margin-bottom: 0;
}

.piece-i {
  background-color: #ec858b;
}
.piece-j {
  background-color: #f1b598;
}
.piece-l {
  background-color: #f8efae;
}
.piece-o {
  background-color: #b5a677;
}
.piece-s {
  background-color: #816e56;
}
.piece-t {
  background-color: #b77c72;
}
.piece-z {
  background-color: #e3be58;
}
.piece-preview {
  background-color: #eee;
}

